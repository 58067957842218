<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers"
            @selected="selectCustomer" />
        <div class="col-12">
            <div class="card">
                <Fieldset legend="Cliente">
                    <div class="p-fluid formgrid grid">
                        <div class="col-12">

                            <BasicFormToolbar>
                                <template #custom-left>
                                    <Button label="Timbrar" icon="pi pi-save" class="p-button-info mr-2" @click="aplicarpago" />
                                </template>
                            </BasicFormToolbar>
                        </div>
                            <FormInputText wrapperClass = "'field col-12 md:col-6 xl:col-6'" label="Nombre" v-model="entity.customer_name"
                            :valid="validate.validations.customer_name" @search="(customerHelper.visible = true)" :search="true" :readonly="true"/>
                            <FormInputText wrapperClass = "'field col-12 md:col-6 xl:col-6'" label="RFC" v-model="entity.customer_rfc"
                            :valid="validate.validations.customer_rfc"/>
                    </div>
                </Fieldset>
                <br>
                <div class="p-fluid formgrid grid">
                    <div class="field col-6 md:col-6 xl:col-6">
                        <Fieldset legend="Depositos" :collapsed="true" :toggleable="true">
                            <div class="field col-12 md:col-12 xl:col-12">
                                <BasicDatatable  @selected-change="getSelectionPayment" :headers="Hpayments" :rows="entities"
                                selectionModeColumn="single" :key="componentKey" :DropdownOptions="CustomerBank" :optionLabel="optionLabel"
                                :optionValue="optionValue"/>
                            </div>
                        </Fieldset>
                    </div>
                    <div class="field col-6 md:col-6 xl:col-6">
                        <Fieldset legend="Facturas" :collapsed="true" :toggleable="true">
                            <div class="field col-12 md:col-6 xl:col-12">
                                <BasicDatatable @selected-change="getSelectionInvoice" :headers="Hinvoices" :rows="invoices"
                                selectionModeColumn="multiple" @cell-editing-complete="editarImporte" :key="componentKey"
                                />
                            </div>
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CustomerPayment } from '../../../models/comercial/CustomerPayment';
import { ReceiptPayment } from '../../../models/comercial/ReceiptPayment';
import { BankAccount } from '../../../models/cxp/BankAccount';
import { PaymentInvoice } from '../../../models/comercial/PaymentInvoice';
import { Customer } from '../../../models/comercial/Customer';
import { HeaderGrid, Rule, ErrorToast, satCatalogo, validate } from '../../../utilities/General';
import Helper from '../../../components/general/HelperDialog.vue';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import { Invoice } from '../../../models/cfdi40/Invoice';
//import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import { CustomerBankAccount } from '../../../models/comercial/CustomerBankAccount';
import moment from 'moment';
import FormInputText from '../../../components/general/FormInputText.vue';
//import FormCalendar from '../../../components/general/FormCalendar.vue';
//import FormInputNumber from '../../../components/general/FormInputNumber.vue';

export default {
    mixins: [Session],

    components: { Helper, Loader, BasicDatatable, BasicFormToolbar, FormInputText },
    data() {
        return {
            componentKey: 0,
            entity: null,
            invoice: null,
            receiptpayment: null,
            payment: null,
            entities: [],
            entitiessave: [],
            selectedPayment: [],
            selectedInvoice: [],
            CustomerPayment: [],
            PaymentInvoice: [],
            loading: false,
            invoices: [],
            customers: [],
            bank_accounts: [],
            c_FormaPago: [],
            c_Moneda: [],
            CustomerBank: [],
            optionLabel: "interbank_key",
            optionValue: "id",
            customer_email: null,
            customersHeaders: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('RFC', 'rfc')],
            rules: [new Rule({ name: 'customer_name' }), new Rule({ name: 'customer_rfc', type: 'rfc' }), new Rule({ name: 'payment_type' }), new Rule({ name: 'amount' }), new Rule({ name: 'currency' }), new Rule({ name: 'exchange_rate' })],
            validate: {
                valid: false,
                validations: {
                    customer_name: null,
                    customer_rfc: null,
                    payment_type: null,
                    amount: null,
                    currency: null,
                    exchange_rate: null,
                },
            },
            Hinvoices: [new HeaderGrid('Serie', 'series'), new HeaderGrid('Fecha', 'date', { type: 'date' }),new HeaderGrid('Cliente', 'receiver_name'), new HeaderGrid('Moneda', 'currency'), new HeaderGrid('Total', 'total', { type: 'currency' }), new HeaderGrid('Saldo', 'balance', { type: 'currency', editable: { component: 'InputNumber' }})],
            Hpayments: [new HeaderGrid('Pago', 'id'), new HeaderGrid('Cuenta Bancaria'), new HeaderGrid('Moneda', 'currency'), new HeaderGrid('Cuenta De pago', 'customer_payment', {Dropdown: true}), new HeaderGrid('Fecha del pago', 'payday', {Calendar: true}), new HeaderGrid('Saldo', 'balance', { type: 'currency' })],
            customerHelper: {
                visible: false,
            },
        };
    },
    created() {
        this.entity = new CustomerPayment(this.session);
        this.receiptpayment = new ReceiptPayment(this.session);
    },
    async mounted() {
        await this.refresh();
        this.c_FormaPago = await satCatalogo(6);
        this.c_Moneda = await satCatalogo(9);
    },
    watch: {
        'entity.currency'(newValue) {
            if (newValue == 'MXN') {
                this.entity.exchange_rate = 1.0;
            }
        },
    },
    methods: {
        generar40() {
            this.entity.generate();
        },
        editarImporte() {
        },
        openNewpayment() {
            this.entity = new CustomerPayment(this.session);
            this.newDialog = true;
        },
        async getSelectionPayment(selection) {
            let customer_payment = selection.customer_payment;
            let payday = selection.payday;
            if(customer_payment != '' && payday != ''){
                this.selectedPayment = selection;
                console.log(JSON.stringify(selection))
            }
            // let payments = selection.filter((x) => x.customer_payment == '' || x.payday == '');
            // if(payments == ''){
            //     this.selectedPayment = selection;
            //     console.log(JSON.stringify(selection))
            // }
            else{
                this.$toast.add({
                    severity: 'error',
                    summary: 'Falta Seleccionar Campo(s)',
                    detail: 'Falta seleccionar campo(s): (Cuenta De pago, Fecha del pago)',
                    life: 3000,
                });
                this.componentKey += 1;
            }
        },
        getSelectionInvoice(selection) {
            this.selectedInvoice = selection;
        },
        async selectCustomer(customer) {
            this.entity.id_customer = customer.id;
            this.entity.customer_name = customer.name;
            this.entity.customer_rfc = customer.rfc;
            this.customer_email=customer.email;
            try{
                this.CustomerBank = await new CustomerBankAccount(this.session).getAll({
                    id_customer: this.entity.id_customer,
                });
            }
            catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
            this.entities = await new CustomerPayment(this.session).all();
            this.entities = this.entities.filter(x => x.id_customer == customer.id || x.id_customer == 0);
            this.entitiessave = this.entities;
            this.invoices = await new Invoice(this.session).payment_invoices({
                    id_customer: this.entity.id_customer
                });
        },
        async refresh() {
            this.loading = true;
            try {
                this.customers = await new Customer(this.session).all();
                this.bank_accounts = await new BankAccount(this.session).all();
                this.entity.currency = 'MXN';
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async savepayment() {
            try {

                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                this.entity.status = 1;
                await this.entity.save();
                this.$toast.add({
                    summary: 'Creacion',
                    detail: 'Informacion guardada con exito',
                });
                this.entities.push(this.entity);
                this.entity = new CustomerPayment(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async timbrar() {
        },
        async aplicarpago() {
            try {

                this.loading = true;
              

                if (!this.selectedPayment) {
                    throw 'Favor de seleccionar un pago';
                }

                if (!this.selectedInvoice) {
                    throw 'Favor de seleccionar una factura.';
                }

                var saldo = 0;
                var totaldepositos = 0;
                var totalfacturas = 0;

                totaldepositos = this.selectedPayment.balance;
                // this.selectedPayment.map(function (elemento) {totaldepositos += elemento.balance;});
                this.selectedInvoice.map(function (elemento) {totalfacturas += elemento.balance;});
                saldo = totaldepositos >= totalfacturas ?  totalfacturas : totaldepositos
                // El ciclo for fue comentado por que en futuras actualizaciones del sistema se podran agregar multiples pagos y 
                // no solo q
                // for (const payment of this.selectedPayment) {
                let payment = this.selectedPayment;
                this.receiptpayments = await new ReceiptPayment(this.session);
                this.receiptpayment.id_payment = payment.id;
                this.receiptpayment.id_customer = this.entity.id_customer;
                this.receiptpayment.payment_type = payment.payment_type;
                this.receiptpayment.currency = payment.currency;
                this.receiptpayment.exchange_rate = payment.exchange_rate;
                this.receiptpayment.amount = saldo;
                let id_reg = payment.customer_payment.split(" ");
                let dateparse=Date.parse(payment.payday);
                let dateFormat = moment(dateparse).format();
                this.receiptpayment.date = dateFormat;
                this.receiptpayment.id_payer_account = id_reg[0];
                this.receiptpayment.payer_account = id_reg[2];
                this.receiptpayment.status = 1;
                this.receiptpayment.cancelation_type = 0;
                this.receiptpayment.id_beneficiary_account = payment.id_beneficiary_account;
                this.receiptpayment.email = this.customer_email;
                console.log(JSON.stringify(this.receiptpayment))
                await this.receiptpayment.SaveReceipt();
                let id_ReceiptPayment = await this.receiptpayment.getRP();

                // Ordenamos las facturas por fecha de vencicimiento
                let arr = this.selectedInvoice.sort((a, b) => new Date(a.date).getTime() > new Date(b.date).getTime());
                console.log(JSON.stringify(arr))
                for (const element of arr) {
                        var aplicado  = saldo >= element.total ? element.total : saldo
                    this.payment = new PaymentInvoice(this.session);
                    var lastpay = await this.payment.checklastpay({
                        id_invoice: element.id,
                    });
                    console.log(JSON.stringify(lastpay))
                    console.log(JSON.stringify(element))
                    if(lastpay == null || lastpay == ""){
                        this.payment.outstanding_balance=0,
                        this.payment.partiality=0,
                        this.payment.previous_amount=element.total,
                        this.payment.id_invoice = element.id,
                        this.payment.date = new Date();
                        this.payment.id_payment = payment.id;
                        this.payment.status = 1;
                        this.payment.amount = aplicado;
                        this.payment.id_ReceiptPayment=id_ReceiptPayment;
                        this.payment.uuid_invoice = element.uuid;
                        let taxes = await this.payment.getTaxes();
                        taxes == 0.0 ? this.payment.taxes_obj = parseInt('01') : 
                        taxes == null ? this.payment.taxes_obj = parseInt('01') : 
                        this.payment.taxes_obj = parseInt('02');
                        this.payment.outstanding_balance=parseInt(element.total)-parseInt(aplicado);
                        this.payment.partiality=parseInt(this.payment.partiality)+1;
                    }else{
                        let outstanding_balance=parseInt(lastpay.outstanding_balance);
                        let partiality=parseInt(lastpay.partiality);
                        console.log("HOLA: "+outstanding_balance+", "+partiality)
                        // let previous_amount=lastpay.previous_amount;
                        this.payment.id_invoice = element.id;
                        this.payment.date = new Date();
                        this.payment.id_payment = payment.id;
                        this.payment.status = 1;
                        this.payment.amount = aplicado;
                        this.payment.id_ReceiptPayment=id_ReceiptPayment;
                        this.payment.uuid_invoice = element.uuid;
                        let taxes = await this.payment.getTaxes();
                        taxes == 0.0 ? this.payment.taxes_obj = parseInt('01') : 
                        taxes == null ? this.payment.taxes_obj = parseInt('01') : 
                        this.payment.taxes_obj = parseInt('02');
                        this.payment.outstanding_balance= outstanding_balance-aplicado;
                        this.payment.partiality= partiality+1;
                        this.payment.previous_amount = outstanding_balance;
                    }
                    await this.payment.save();
                    
                    saldo -=aplicado;

                    if(saldo <= 0) break;
                }

                this.entities = await new CustomerPayment(this.session).all();
                this.entities = this.entities.filter(x => x.id_customer == this.entity.id_customer || x.id_customer == 0);
                this.invoices = await new Invoice(this.session).payment_invoices({
                id_customer: this.entity.id_customer
                });
                this.entitiessave = this.entities;
                    //Generar XML
                    await this.payment.generate();

                    //Timbrado XML
                    let response = await this.payment.certificate();
                    this.$toast.add({
                    severity: 'success',
                    summary: 'Timbrado',
                    detail: response.mensaje,
                    life: 3000,
                });

                    
                // }
                this.componentKey += 1;
                
                    //this.entity = new PaymentInvoice(this.session);
                    //this.entity.id_payment = pago;
                    //let response = await this.entity.certificate();
                    //this.$toast.add({
                    //    severity: 'success',
                    //    summary: 'Timbrado',
                    //    detail: response.mensaje,
                    //    life: 3000,
                    //});
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
                this.componentKey += 1;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>


<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }

    
}
</style>