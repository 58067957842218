import {
    Abstract
} from './Abstract';

import axios from "axios";

export class PaymentInvoice extends Abstract {
    constructor(session) {
        super('comercial/PaymentInvoice', session);
        this.id_payment = null;
        this.id_invoice = null;
        this.amount = null;
        this.date = new Date();
        this.status = null;
        this.id_ReceiptPayment = null;
        this.taxes_obj = null;
        this.uuid_invoice = null;
        this.outstanding_balance = null;
        this.partiality = null;
        this.previous_amount = null;
    }

    async generate() {
        let response = await axios.post(this.controller + '/generate/40', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
            }
        });
        return response.data;
    }

    
    async certificate() {
        if (this.id_payment == null || this.id_payment == 0 || this.id_payment == "") 
            throw "Seleccione una pago para timbrar"
        let response = await axios.post(this.controller + '/timbrar/40/' + this.id_ReceiptPayment, null, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
    async getTaxes() {
        let response = await axios.get(this.controller + "/getTaxes", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                id: this.id_invoice,
            }
        });
        return response.data;
    }
    async checklastpay(params) {
        let response = await axios.get(this.controller + "/checklastpay", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                id_invoice: params.id_invoice,
            }
        });
        return response.data;
    }
}

