import {
    Abstract
} from './Abstract';

import axios from "axios";

import moment from "moment";

export class ReceiptPayment extends Abstract {
    constructor(session) {
        super('comercial/ReceiptPayment', session);
        this.id_payment = null;
        this.id_customer = null;
        this.date = null;
        this.payment_type = null;
        this.currency = null;
        this.exchange_rate = 0.00;
        this.amount = 0.00;
        this.id_payer_account = null;
        this.payer_account = null;
        this.id_beneficiary_account = null;
        this.xmlfilename = null;
        this.is_certificated = false;
        this.certification_date = null;
        this.receiver_email = null;
        this.original_chain = null;
        this.sat_certificate = null;
        this.sat_stamp = null;
        this.cfdi_certificate = null;
        this.cfdi_stamp = null;
        this.uuid = null;
        this.cancelation_status = null;
        this.cancelation_reason = null;
        this.acuse = null;
        this.uuid_sustitution = null;
        this.status= null;
        this.cancellation_related_receipt = null;
        this.email = null;
        this.cancelation_date = new Date();
    }
    async getRP() {
        let response = await axios.get(this.controller + "/getRP", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }
    async SaveReceipt() {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/SaveReceipt', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                id: this.id_payment,
                customer: this.id_customer,
                dateishon: moment(this.date).format(),
                payment_type: this.payment_type,
                amount: this.amount,
                balance: this.balance,
                id_payer_account: this.id_payer_account,
                payer_account: this.payer_account,
                id_beneficiary_account: this.id_beneficiary_account,
                currency:  this.currency,
                exchange_rate: this.exchange_rate,
            }
        });
        return response.data;
    }
    async getAdmin(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query+" "+this.id+" "+this.currency))
        let response = await axios.get(this.controller + "/getAdmin" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
                currency: this.currency,
                id: this.id,
            }
        });
        return response.data;
    }
    async getReceipt(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query))
        let response = await axios.get(this.controller + "/getReceipt" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
            }
        });
        return response.data;
    }
    async getAll(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query))
        let response = await axios.get(this.controller + "/getAll" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
            }
        });
        return response.data;
    }
    async cancel(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(params.uuid))
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para cancelar"

            console.log(this.controller + "/cancelar/40/" + this.id + "/" + params.cancelation_reason + "/" + params.uuid)
        let response = await axios.post(this.controller + "/cancelar/40/" + this.id + "/" + params.cancelation_reason + "/" + params.uuid, this,{
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
            }
        });
        console.log("Resouest"+JSON.stringify(response.data))
        return response.data;
    }
    async getLog(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query))
        let response = await axios.get(this.controller + "/getLog" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user,
            }
        });
        return response.data;
    }
    async downloadPDF() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el PDF"
        let response = await axios({
                method: "get",
                url: this.controller  + "/pdf/download/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.xmLfilename.split('.')[0] + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
    }
}